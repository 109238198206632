import * as React from "react"
import { Fragment } from "react"
import Footer from "./footer"

const Layout = ({ location, title, children, footer }) => {

  return (
    <Fragment>
      <a href="#main-content" className="header-skip">Skip to main content</a>
      {children}
      <Footer details={footer} location={location} />
    </Fragment>
  )
}

export default Layout
