import * as React from "react"
import { Fragment } from "react"
import { Link } from "gatsby"
import bmoLogoDarkBg from '../images/bmo-roundel-logo-dark-bg.svg'
import bmoLogoLightBg from '../images/bmo-roundel-logo-light-bg.svg'


const HeaderNav = ({bannerColor}) => {
  let bannerClass, logoType
  if (bannerColor === 'BMO Blue') {
    bannerClass = "full-blue"
    logoType = true
  } else if (bannerColor === 'White') {
    bannerClass = "full-white"
    logoType = false
  }
    return (
        <Fragment>
        <div className={`bmo-header ${bannerClass} underline`}>
        <div className="bmo-container">
          <div className="bmo-logo">
              <Link to="#">
                { logoType ? <img src={bmoLogoDarkBg} alt="BMO Bank of Montreal Logo" /> : <img src={bmoLogoLightBg} alt="BMO Bank of Montreal Logo" />}
            </Link>
          </div>
          <div className="header-content">
            {/* <nav className="primary-nav" aria-label="Main">
              <ul>
                <li>
                  <Link to="/" className="primary-link">Work</Link>
                </li>
                <li>
                  <a href="" target="_blank" rel="noopener noreferrer" className="primary-link">Contact</a>
                </li>
              </ul>
            </nav> */}
          </div>
        </div>
      </div>
      </Fragment>
    )
}

export default HeaderNav