import * as React from "react"
import LinkButton from "./linkbutton"
import bmoLogo from '../images/bmo-roundel-logo-dark-bg.svg'
import { StaticImage } from "gatsby-plugin-image"

const Footer = ({ details: { footer, header, buttonOptions }, location }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <footer>
      <section className={`footer-container root-${isRootPath}`}>
        <div className="bmo-container">
          <div className="footer-container--about-us">
            <h2>{header}</h2>
            <p>{footer}</p>
            {buttonOptions && <LinkButton text={buttonOptions.copy} link={buttonOptions.url} buttonclass="footer-button link-button" iconclass={isRootPath ? "filter-ultramarine" : "filter-white"} target="_blank" />}
          </div>
        </div>
      </section>
      <section className={`footer-container root-${isRootPath}`}>
        <div className="bmo-container">
          {
            isRootPath ?
              <div className="footer-container--logo">
                <div className="footer-container--logo-copyright">
                  <p>Copyright {new Date().getFullYear()}. BMO Financial Group</p>
                  <img src={bmoLogo} alt="BMO Bank of Montreal Logo" className="footer-logo" />
                </div>
              </div>
              :
              <div className="footer-container--logo">
                <div className="footer-container--logo-copyright">
                  <a href="#main-content"><span className="sr-only">scroll to top</span><StaticImage src="../images/filled-arrow-up.png" alt="arrow pointing up to scroll up" width={48} height={48} /></a>
                </div>
              </div>
          }
        </div>
      </section>
    </footer>
  )
}

export default Footer
